import React from "react"
import ArticleList from "../../components/ArticleList"
import Layout from "../../components/layouts/Layout"
import SEO from "../../components/Seo"

const Articles = () => {
  return (
    <Layout>
      <SEO title="Articles" description="Articles on Wine and Cider" />
      <div className="prose mx-auto">
        <h1>Articles</h1>
        <ArticleList />
      </div>
    </Layout>
  )
}

export default Articles
